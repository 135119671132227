import React from 'react'

const Feature3 = () => {
  return (
    <div className="feature" id="benefits">
  <div className="container mt-2">
    <div className="row justify-content-center">
      <div className="col-md-7 text-center">
        <div className="section-head">
          <h2 className="title wow fadeInUp mt-4" data-wow-duration="0.3s" data-wow-delay="0.3s">Earn up to 17% profit of your investment in 1 Week</h2>
          <p className="text wow fadeInUp" data-wow-duration="0.3s" data-wow-delay="0.3s">
          When you invest with Cran Capital you can get profits up to 17% depending on the plan you choose to invest in. Investments count towards your overall Cran Capital account balances and can unlock additional investment benefits.
          </p>
        </div>
      </div>
    </div>
    <div className="row">
      <div className="col-md-6  wow fadeInLeft" data-wow-duration="0.3s" data-wow-delay="0.3s">
        <div className="item-box">
          <div className="thumb">
            <div className="icon">
              <img src="https://res.cloudinary.com/dzxyvdq14/image/upload/v1659906460/f-item-1.png" alt="features" />
            </div>
          </div>
          <div className="content">
            <h4 className="lasthead">Lower Rates.No Transaction fees</h4>
            <p className="text">
              Our technology streamlines the
              process, which means streamlined costs. Get the best rates, with no fees.
            </p>
          </div>
        </div>
      </div>
      <div className="col-md-6 wow  wow fadeInRight" data-wow-duration="0.3s" data-wow-delay="0.3s">
        <div className="item-box">
          <div className="thumb">
            <div className="icon">
              <img src="https://res.cloudinary.com/dzxyvdq14/image/upload/v1659906460/f-item-2.png" alt="features" />
            </div>
          </div>
          <div className="content">
            <h4 className="lasthead">Simpler application process</h4>
            <p className="text">
              Complete your online application in your own time, with local customer support available
              24/7 .
            </p>
          </div>
        </div>
      </div>
      <div className="col-md-6  wow fadeInLeft" data-wow-duration="0.3s" data-wow-delay="0.3s">
        <div className="item-box">
          <div className="thumb">
            <div className="icon">
              <img src="https://res.cloudinary.com/dzxyvdq14/image/upload/v1659906460/f-item-3.png" alt="features" />
            </div>
          </div>
          <div className="content">
            <h4 className="lasthead">Get faster approval of withdrawals</h4>
            <p className="text">
              Thanks to our auto-assessment
              technology, we assess you as you apply to slash wait times.
            </p>
          </div>
        </div>
      </div>
      <div className="col-md-6  wow fadeInRight" data-wow-duration="0.3s" data-wow-delay="0.3s">
        <div className="item-box">
          <div className="thumb">
            <div className="icon">
              <img src="https://res.cloudinary.com/dzxyvdq14/image/upload/v1659906460/f-item-4.png" alt="features" />
            </div>
          </div>
          <div className="content">
            <h4 className="lasthead">Secure process</h4>
            <p className="text">
              Get tech-nerd grade security,
              encryption &amp; hosting. Plus, confidence in our Australian Credit Licence
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

  )
}

export default Feature3