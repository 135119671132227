import React from 'react';
import Hero from './inc/Hero';
import Footer from './Footer';
import About from './inc/About';
import Deposit from './inc/Deposit';
import Feature from './inc/Feature';
import Plans from './inc/Plans';
import Counter from './inc/Counter';
import Youtube from './inc/Youtube';
import Gateway from './inc/Gateway';
import Testimonial from './inc/Testimonial';
import Widgets from './inc/Widgets';
import CoinList from './inc/CoinList';
import Feature1 from './inc/Feature1';
import Mission from './inc/Mission';
import Feature2 from './inc/Feature2';
import Feature3 from './inc/Feature3';
const Home = () => {

  return (
    <>
       <Hero/> 
       <CoinList/>
       <Feature1/>
       <Mission/>
       <Feature3/>
       <Feature2/>
       {/* <About/> */}
       <Deposit/>
       <Feature/>
       <Plans/>
       <Counter/>
       <Widgets/>       
       <Youtube/>
       <Gateway/>
       <Testimonial/>
       {/* <Footer/> */}
    </>
   
  )
}

export default Home